<template>
    <div class="project-progress-record-cell">
        <van-row @click="to(data.to)" class="card" type="flex" justify="center" align="center">
            <van-col offset="1" span="5" class="progress">
              {{ data.travelDate }}
              <i v-if="data.line" class="line"></i>
            </van-col>
            <van-col span="9">
                <van-row>
                    <van-col offset="1" span="23" class="text van-ellipsis title">{{ data.titleName }}</van-col>
                </van-row>
                <van-row>
                    <van-col offset="1" span="13" class="text info">
                        {{ data.travelType }}
                    </van-col>
                    <van-col offset="1" span="9" class="text van-ellipsis info">
                        {{ data.createdPeople }}
                    </van-col>
                </van-row>
            </van-col>
            <van-col span="8">
                <van-row class="right">
                    <van-col span="16">
                        <div class="status" :style="'color:'+map[data.status]">{{data.status}}</div>
                    </van-col>
                    <van-col span="8">
                        <van-icon name="arrow" class="icon" color="#C0BFCE"/>
                    </van-col>
                </van-row>
            </van-col>
        </van-row>
    </div>
</template>

<script>
    export default {
        name: "ProjectProgressRecordCell",
        data() {
            return {
                map: {
                    '已批复': '#1AAD19',
                    '未批复': '#F00707',
                    '批复中': '#318EF5',
                }
            }
        },
        methods: {
            to(to) {
                this.$router.push(to);
            }
        },
        props: {
            data: Object
        }
    }
</script>
<style scoped>
    .project-progress-record-cell {
        background: #ffffff;
        margin-top: 4px;
    }

    .project-progress-record-cell .card {
        padding-top: 5px;
    }

    .project-progress-record-cell .progress {
        position: relative;
        font-weight: 700;
        font-size: 12px;
        color: #101010;
        font-style: normal;
        letter-spacing: 0;
        line-height: 24px;
        text-decoration: none;
    }

    .project-progress-record-cell .progress i.line:nth-last-of-type(1){
        display: block;
        position: absolute;
        height: 30px;
        border-left: 1px solid;
        left: 50%;
        color: #5b6b73;
    }


    .project-progress-record-cell .text {
        text-align: left;
    }

    .project-progress-record-cell .title {
        font-weight: 400;
        font-size: 15px;
        color: #111111;
        font-style: normal;
        letter-spacing: 0;
        line-height: 27px;
        text-decoration: none;
    }

    .project-progress-record-cell .info {
        font-weight: 400;
        font-size: 12px;
        color: #827E7E;
        font-style: normal;
        letter-spacing: 0;
        line-height: 20px;
        text-decoration: none;
    }

    .project-progress-record-cell .icon {
        line-height: 24px;
    }
</style>
