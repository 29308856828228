<template>
  <div class="visit-replay-list">
    <div class="title">

    <!-- 搜索框-->
    <van-search v-model="createdName" placeholder="请输入负责人名称" @cancel="onCancel()" show-action/>

    <van-row style="margin-top: -10px; margin-left: -10px">
      <van-col span="7" offset="1">
        <el-select v-model="fplx" placeholder="复盘类型">
          <el-option
              v-for="item in left_list"
              :key="item.value"
              :label="item.text"
              :value="item.value">
          </el-option>
        </el-select>
      </van-col>
      <van-col span="7" offset="0">
        <el-select v-model="fpzt" placeholder="复盘状态">
          <el-option
              v-for="item in right_list"
              :key="item.value"
              :label="item.text"
              :value="item.value">
          </el-option>
        </el-select>
      </van-col>
      <van-col span="7" offset="1">

      </van-col>
    </van-row>

    </div>

    <!-- 列表-->
    <div ref="fresh" :style="{height:freshHeight +'px','overflow-y':'scroll','box-sizing': 'border-box'}">
      <div v-if="list.length<=0">
        <van-empty image="error" description="没有数据"/>
      </div>
      <div v-else>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
              v-model="loading"
              loading-text="加载中"
              :finished="finished"
              finished-text="没有更多了"
              @load="nextPage"
              style="margin-bottom: 45px"
          >
            <project-progress-record-cell v-for="item in list" v-bind:key="item.id" :data="item"/>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="3"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import ProjectProgressRecordCell from "@/components/ProjectProgressRecordCell";
import {Toast} from "vant";

export default {
  name: "BulkCrmsVisitReplayList",
  components: {CrmTabBar, ProjectProgressRecordCell},
  data() {
    return {
      list: [],
      createdName: '',
      //列表高度
      freshHeight: 0,
      //下拉标识
      isLoading: false,
      currentPage: 1, // 当前页码
      pageSize: 25, // 每页个数
      loading: false, // 加载下一页
      finished: false, // 是否加载完了
      refreshing: false,
      fplx: '',
      left_list: [
        { text: '全选', value: '000' },
        { text: '电话拜访', value: "('002')" },
        { text: '客户来访', value: "('004')" },
        { text: '走访拜访', value: "('001', '003')" },
      ],
      fpzt: '',
      right_list: [// 右侧菜单 选项数组
        { text: '全选', value: '000' },
        { text: '未批复', value: '00' },
        { text: '批复中', value: '01' },
        { text: '已批复', value: '02' },
      ]
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 70
    this.onRefresh();
  },
  watch: {
    //监听负责人名头
    'createdName': function () {
      this.list = [];
      this.onRefresh();
    },
    //监听复盘类型下拉
    'fplx': function () {
      this.list = [];
      this.onRefresh();
    },
    //监听复盘状态下拉
    'fpzt': function () {
      this.list = [];
      this.onRefresh();
    },
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.currentPage = 1
      this.list = []
      this.finished = false
      this.refreshing = true
      this.loading = true
      this.getData()
    },
    onCancel() {
      this.createdName = ''
      this.fplx = ''
      this.fpzt = ''
      this.onRefresh();
    },
    // 加载下一页
    nextPage() {
      setTimeout(() => {
        if (!this.finished) {
          this.loading = false
          this.currentPage += 1
          this.getData()
        }
      }, 500)
    },
    // 下拉刷新
    getData() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      this.loading = true
      this.$ajax.post('/api/ajax/replay/getDisReplayList.json',
          {
            createdPeople: this.$store.getters.userId,
            titleId: '',
            createdName: this.createdName,
            replayId: '',
            current: this.currentPage,
            size: this.pageSize,
            travelType: this.fplx,
            status: this.fpzt
          }).then(res => {
        this.loading = false
        if (res.status == 200) {
          // 如果下拉刷新，那么数据清空重新获取第一页
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          console.log(res.data)
          res.data.forEach(item => {
            this.list.push(item)
          })
          this.listRow = this.list.length
          // 如果数据加载完毕，finished 标志位置为 true，滑到页面底部则不会再触发 nextPage() 方法了。currentPage 重置为 1
          if (res.data.length < this.pageSize - 1) {
            this.finished = true
          } else {
            this.finished = false
          }
          for (let i = 0; i < this.list.length; i++) {
            this.list[i].to = '/BulkCrmsVisitReplayEdit/' + this.list[i].titleId
            this.list[i].line = true;
            if (i == this.list.length - 1) {
              this.list[i].line = false;
            }
          }
          Toast.success('加载成功');
        }
      }).catch(err => {
        Toast.fail('加载失败');
        console.log(err)
        this.$message.error('查询失败！');
      })
    }
  }
}
</script>

<style scoped>
.visit-replay-list .title{
  background-color: #FFFFFF;
}
.visit-replay-list .el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.visit-replay-list .el-icon-arrow-down {
  font-size: 12px;
}

</style>

<style>
.visit-replay-list .el-input__inner{
  border: none;
}
</style>